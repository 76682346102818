import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { DOM_KEY_LOCATION } from '@testing-library/user-event/dist/keyboard/types';
import React, { Component } from 'react';

const capitalizeFirstLowercaseRest = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

class Search extends Component {

  token = null;
  state = {
    query: "",
    street: [],
    newValue: ""
  };

  newPlaceholder = "Wprowadź ulicę...";
  
  znalezionaUlica = "";

  handleChange = e => {    


    if (e.key === 'Enter') {
      //console.log(e.target.value);
      this.props.getStreet(e.target.value);
    }
  }

  onChangeSearch = e => {
    if (e.keyCode) {  // 38 - stralka do góry, 40 - strzalka w dol, 13 - enter
      if (e.keyCode == '38') {
        console.log('gora = '+e.keyCode );

      } else if (e.keyCode == '40') {
        console.log('dol = '+e.keyCode );

      } else if  (e.keyCode == '13') {
        console.log('enter = '+e.keyCode );
      }
    }

    console.log(e.target.value);
    

    const { value } = e.target;
    this.setState({
      query: value
    });

    this.setState({
      newValue: value
    });

    this.search(value);
    //console.log(value);
    //this.state.newValue = value;
  };

  streetSearch = (streetSearchNew, ulica) => {    

    this.search("");
    this.props.getStreet(streetSearchNew);
    this.newPlaceholder = "Wprowadź ulicę...";

    if (ulica) this.znalezionaUlica = 'ul. '+ulica;
    
    this.setState({
      newValue: ""
    });
  }
  search = query => {
    //const url = `https://swapi.dev/api/people?search=${query}`;
    //const url = `http://go.netbox.net.pl/api/api/index.php/api/lask/?SearchStreet=${query}`;
    const url = `http://lask.netbox.net.pl/api/index.php/api/lask/?SearchStreet=${query}`;
    //const token = {};
    //this.token = token;

    fetch(url)
      .then(results => results.json())
      .then(results => {
        //if (this.token === token) {
          //console.log(data.results);
          //this.setState({ people: data.results });
          this.setState({ street: results });
          
        //}
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({ street: "" });        
      });
  };

  componentDidMount() {
    this.search("");
  }



  render() {
    return (
      <div className="table">
        <div className="container search">
          <div className="dropdown">
                <span><input
              type="text"
              className="search-box"
              placeholder={this.newPlaceholder}
              value={this.state.newValue}
              onChange={this.onChangeSearch}
              onKeyDown={this.onChangeSearch}
            /></span>
           
                <div className='dropdown-content-search'>
                {this.state.street.map(wynik => {          
                    return(
                      <p
                        key={wynik.ulica}
                        className='dropdown-txt'

                        //onKeyPress={this.streetSearch(wynik.rejon_nazwa,wynik.ulica)}

                        onClick={
                        () => this.streetSearch(
                                                capitalizeFirstLowercaseRest(wynik.rejon_nazwa),
                                                capitalizeFirstLowercaseRest(wynik.ulica))} 
                      > {capitalizeFirstLowercaseRest(wynik.ulica)}</p>
                    )
                  })}
                </div>
            </div>
            <div className='findStreet'>{this.znalezionaUlica ? this.znalezionaUlica : 'ul. Rolnicza'}</div>          
        </div>
        
      </div>
    );
  }
}
  
export default Search;