import React, {useState} from 'react';
import './App.css';
import Calendar from './components/Calendar';
import Search from './components/Search';


function App() {

  const [ streetSearch, setStreetSearch ] = useState(null);

  const getStreet = (streetSearch) => {    
    setStreetSearch(streetSearch);
  }

  return (
    <div className="">
      <Search getStreet={getStreet}/>
      <Calendar newStreetSearch={streetSearch}/>
    </div>
  );
}

export default App;
