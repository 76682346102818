import React, {useState, useEffect} from 'react';
//import Harmonogram from '../harmonogram.json';

function Calendar(props) {
    
    //const monthNamesShort = ["st", "lu", "mar", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"];
    const monthNames = ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"];
    const daysShort = ["P", "W", "Ś", "C", "P", "S", "N"];    
    //const daysShort2 = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];    
    //const days = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];

    //const [ harmonogram, setHarmonogram ] = useState( [] );
   
    
    const newDate = new Date();
    const dayThis = newDate.getDate() ;
    const monthThis = newDate.getMonth() ;
    const yearThis = newDate.getFullYear();
    //const dayOfWeek = newDate.getDay() ;

    const [row, setRow] = useState(monthThis+1);
    const [street, setStreet] = useState('Rolnicza');

    useEffect ( () => {
      if (props.newStreetSearch) setStreet(props.newStreetSearch);
    }, [props.newStreetSearch] )

     const dec = (evt) => {
      if (row >1) setRow(row-1)    
    }

    const add = (evt) => {
      if (row < 12)  setRow(row+1)    
    }

    const streetSelect = (street) => {
      setStreet(street);   
    }
    
    /*
    const rolnicza = (evt) => {
      setStreet('rolnicza');   
    }
    const miodowa = (evt) => {
      setStreet('miodowa');   
    }*/


    let metalPlastik = [];
    let papier = [];
    let szklo = [];
    let bio = [];
    let zmieszane = [];

    

    metalPlastik.length = 0;
    papier.length = 0;
    szklo.length = 0;
    bio.length = 0;
    zmieszane = 0;
    

    let zaladowanoOk = 0;


// =======================================================
//function MyComponent()  

   //const MyComponent = () => {

    
      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [ Harmonogram, setItems ] = useState( [] );

      
      
      // Note: the empty deps array [] means
      // this useEffect will run once
      // similar to componentDidMount()
      useEffect(() => {
        //fetch("http://localhost/api/index.php/api/rolnicza")
        //fetch(`http://go.netbox.net.pl/api/api/index.php/api/lask/?u=`+street)
        fetch(`http://lask.netbox.net.pl/api/index.php/api/lask/?u=`+street)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [street])


      /*
      Harmonogram.map((harmonogram1) => {
        if (harmonogram1.miesiac == row)  {
          metalPlastik = harmonogram1.metal_plastik;
          papier = harmonogram1.papier;
          szklo = harmonogram1.szklo;
          bio = harmonogram1.bio;
          zmieszane = harmonogram1.zmieszane;
          console.log(harmonogram1.miesiac );
          console.log('bio '+bio);
        }
      })
*/

    
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className='container-loader'><div className="loader"></div></div>;
      } else {
          Harmonogram.map((harmonogram) => {
            if ( Number(harmonogram.miesiac) === row)  {
              metalPlastik = harmonogram.metal_plastik.split(',').map( n => parseInt(n, 10));
              papier = harmonogram.papier.split(',').map( n => parseInt(n, 10));
              szklo = harmonogram.szklo.split(',').map( n => parseInt(n, 10));
              bio = harmonogram.bio.split(',').map( n => parseInt(n, 10));
              zmieszane = harmonogram.zmieszane.split(',').map( n => parseInt(n, 10));
            }
            //console.log(zaladowanoOk++);
            zaladowanoOk = 1;
            return zaladowanoOk;
          })
      }/**/
    //}

    
//===================================  




    function DayOfWeek() {


      return (

        <div className='container day-name'>

        {daysShort.map((number, index) =>
           <div key={index} 
           className={
            "cell cell-"+(index+1)
          }
            > {number} </div>
        )}

        </div>
      )

    }

    function CreateMonth() {   
      


        //pobieramy liczbę dni w miesiącu
        const daysInMonth = new Date(yearThis, row, 0).getDate();

        //console.log('daysInMonth '+dayThis+', miesiąc: '+monthNames[monthThis]+', dzień '+dayOfWeek);

        //pobieramy pierwszy dzień miesiąca
        const tempDate = new Date(yearThis, row-1, 1);
        let firstMonthDay = tempDate.getDay();

        if (firstMonthDay === 0) firstMonthDay = 7;

        //liczba wszystkich komórek - i pustych i z dniami
        const j = daysInMonth + firstMonthDay - 1;

        //const pusteDiv = [];


      let calendarDiv = [];
      calendarDiv.length = 0;

      
                  //let rowDiv = 0;
                  let nColumns = 0;

      return (
              <div className='container'>

                {(() => {

                  for (let i = 1; i<=j; i++) {
                    /*
                    if (i % 7 === 0) {
                      //rowDiv++;
                      //calendarDiv.push(<div>);
                    }  */               
                    
                    if (nColumns % 7 === 0) {
                      nColumns = 0;
                      //calendarDiv.push(<div>);
                    }     

                    // zaczynamy sprawdzac harmonogram
                    let metalPlastikClass = ""
                    let papierClass = ""
                    let szkloClass = ""
                    let bioClass = ""
                    let zmieszaneClass = ""

                    let actual = ""

                    let metalPlastikDiv;
                    let papierDiv;
                    let szkloDiv;
                    let bioDiv;
                    let zmieszaneDiv;

                    let allCircle = 0;
                    let nCircle = 0;
                    let dayColor = "";

                    //zaznaczenie auktualnego dnia
                    if (row === monthThis+1 && i-firstMonthDay+1 === dayThis) {
                      actual = " actual"
                    }
                    
                    /*
                    Harmonogram.map((harmonogram) => {
                      if (harmonogram.miesiac == 4)  {
                        metalPlastik = harmonogram.metal_plastik;
                        papier = harmonogram.papier;
                        szklo = harmonogram.szklo;
                        bio = harmonogram.bio;
                        zmieszane = harmonogram.zmieszane;

                        //console.log('metal plastik '+Harmonogram);
                      }
                    })*/

                    //sprawdzenie ile jest w tym dniu
                    for (let k=0; k < metalPlastik.length; k++) {
                      if (metalPlastik[k] && metalPlastik[k]  === (i-firstMonthDay+1) ) allCircle++;
                    }

                    for (let k=0; k < papier.length; k++) {
                      if (papier[k] && papier[k]  === (i-firstMonthDay+1) ) allCircle++;
                    }

                    for (let k=0; k < szklo.length; k++) {
                      if (szklo[k] && szklo[k]  === (i-firstMonthDay+1) ) allCircle++;
                    }

                    for (let k=0; k < bio.length; k++) {
                      if (bio[k] && bio[k]  === (i-firstMonthDay+1) ) allCircle++;
                    }

                    for (let k=0; k < zmieszane.length; k++) {
                      if (zmieszane[k] && zmieszane[k]  === (i-firstMonthDay+1) ) allCircle++;
                    }

                    //console.log('dzień: '+(i-firstMonthDay+1)+' ilość: '+allCircle);

                    //if (metalPlastik && metalPlastik  === (i-firstMonthDay+1) ) metalPlastikClass = " metal-plastik";
                    //if (papier && papier  === (i-firstMonthDay+1) ) papierClass = " papier";
                    //if (szklo && szklo  === (i-firstMonthDay+1) ) szkloClass = " szklo";
                    //if (bio && bio  === (i-firstMonthDay+1) ) bioClass = " bio";

                    

                    for (let k=0; k < metalPlastik.length; k++) {
                      if (metalPlastik[k] && metalPlastik[k]  === (i-firstMonthDay+1) ) {
                        metalPlastikClass = " metal-plastik";
                        nCircle++;
                        metalPlastikDiv = <div 
                                    className={
                                      'circle ' + metalPlastikClass + ' circle-' + nCircle + '-' + allCircle
                                    }></div>;
                      }
                    }

                    for (let k=0; k < papier.length; k++) {
                      if (papier[k] && papier[k]  === (i-firstMonthDay+1) ) {
                        papierClass = " papier";
                        nCircle++;
                        papierDiv = <div
                                    className={
                                      'circle ' + papierClass + ' circle-' + nCircle + '-' + allCircle
                                    }></div>;
                      }
                    }

                    for (let k=0; k < szklo.length; k++) {
                      if (szklo[k] && szklo[k]  === (i-firstMonthDay+1) ) {
                        szkloClass = " szklo";
                        nCircle++;
                        szkloDiv = <div 
                                    className={
                                      'circle ' + szkloClass + ' circle-' + nCircle + '-' + allCircle
                                    }></div>;
                      }
                    }

                    for (let k=0; k < bio.length; k++) {
                      if (bio[k] && bio[k]  === (i-firstMonthDay+1) ) {
                        if (nColumns === 5) dayColor = " black";
                        bioClass = " bio";
                        nCircle++;
                        bioDiv = <div 
                                    className={
                                      'circle ' + bioClass + ' circle-' + nCircle + '-' + allCircle
                                    }></div>;
                      }
                    }

                    for (let k=0; k < zmieszane.length; k++) {
                      if (zmieszane[k] && zmieszane[k]  === (i-firstMonthDay+1) ) {
                        zmieszaneClass = " zmieszane";
                        nCircle++;
                        zmieszaneDiv = <div 
                                    className={
                                      'circle ' + zmieszaneClass + ' circle-' + nCircle + '-' + allCircle
                                    }></div>;
                      }
                    }

                    //console.log(metalPlastik+' - dayThis '+(i-firstMonthDay+1)+'bio '+bio+'szklo '+szklo);

                    nColumns++;                    

                    if (i < firstMonthDay) calendarDiv.push(<div className={
                                                              "cell cell-" + nColumns
                                                            }  key={i}></div>)
                    else  calendarDiv.push(<div className={
                                            "cell day cell-" + nColumns + actual + dayColor // + metalPlastikClass + papierClass + szkloClass + bioClass
                                          } key={i}> {zmieszaneDiv}  {metalPlastikDiv}  {papierDiv}  {szkloDiv}  {bioDiv} <span>{i-firstMonthDay+1} </span></div>);

                    

                  }
                  return calendarDiv;
                })() }
      </div>)
    }

  return (
    <div className="table">      
        <div className='top'> 
            <div className='container month-name'>
                <button className='btn cell' onClick={ () => dec()}> {'<'} </button>
                <span className='cell month'>{monthNames[row-1]}  </span>
                <button className='btn cell' onClick={ () => add()}> {'>'} </button>
            </div>             
        </div>
        <div className="">< DayOfWeek /></div>
        <div className="">< CreateMonth /></div>      
    </div>
  );
}

export default Calendar;
